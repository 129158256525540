body {
    height: 100vh;
}

#root {
    min-height: 100%;
}

.formulario {
    background-color: #f5f5f561;
    padding: 10px 0px;
}

.spin-cargando {
    position: fixed;
    z-index: 1031;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}