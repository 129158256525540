.ant-modal.body .spin-wrapper {
    height: 400px;
}

.ant-modal.body .spin-wrapper .spin-cargando {
    position: fixed;
    z-index: 1031;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}