@import '~antd/dist/antd.less';

.main-layout {
    height: 100vh;
}

.container-fluid {
    margin: 0 auto;
    max-width: 1200px;
    padding: 0 10px;
}

.ant-layout {
    background: #fff !important;
}

.main-layout .ant-layout-header {
    background: #f8f8f8;
    padding: 0;
}

.main-layout .ant-layout-content {
    padding: 10px 20px;
    overflow: auto;
}

.main-layout .ant-layout-content .spin-cargando {
    position: fixed;
    z-index: 1031;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.main-layout .ant-layout-footer{
    text-align: right;
    
    background: rgb(0 0 0 / 85%);
    height: 30px;
}

.main-layout .ant-layout-footer .link {
    color: #fafafa;
    font-size: 14px;
}

p {
    font-size: 16px;
    line-height: 1.6;
}
  
.bgGray {
    background: #fafafa;
}