.empty-text {
    position: fixed;
    top: 50%;
    left: 50%;
}

.spin-wrapper .spin-cargando {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}